:root {
    --primary: #eb3d56;
    --overstock: #52b3a1;
    --accent: #ffffff;
    --gunmetal: #224552;
    --text_light: #727272;
    --gray_light: #e9e9e9;
}

.react-datepicker-popper[data-placement^="top"] {
    padding-bottom: 4px !important;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker {
    border: 0 !important;
    border-radius: 20px !important;
    box-shadow: rgba(8, 11, 14, 0.06) 0px -1px 1px -1px, rgba(8, 11, 14, 0.1) 0px -4px 6px -1px,
        rgba(8, 11, 14, 0.1) 0px 4px 6px -1px;
}

.react-datepicker__navigation {
    border-radius: 10px;
    background-color: var(--gray_light) !important;
    margin: 0.75rem;
}

.react-datepicker__navigation-icon::before {
    width: 7px !important;
    height: 7px !important;
    border-color: var(--gunmetal) !important;
    top: 7px !important;
    left: -5px !important;
}

.react-datepicker__month-container {
    padding: 0.75rem;
}

.react-datepicker__header {
    background: white !important;
    border-bottom: 0 !important;
    border-radius: 20px !important;
    padding: 0.5rem 0 !important;
}

.react-datepicker__current-month {
    font-size: 20px !important;
}

.react-datepicker__day-names {
    margin-top: 0.5rem;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 10vw !important;
    line-height: 10vw !important;
    border-radius: 10px !important;
    font-size: 18px;
}

.react-datepicker__day--today {
    background-color: var(--gray_light) !important;
    border-radius: 10px;
}

.react-datepicker__day--selected {
    background-color: var(--gunmetal) !important;
}

.react-datepicker__day--outside-month {
    color: var(--text_light) !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
}

input.datepicker {
    background: rgb(233, 233, 233);
    line-height: 32px;
    font-size: 22px;
    border-radius: 20px;
    text-align: center;
    height: 3.5rem;
}
