:root {
    --primary: #eb3d56;
    --overstock: #52b3a1;
    --accent: #ffffff;
    --gunmetal: #224552;
    --text_light: #727272;
}

.input-range__slider {
    appearance: none;
    background: white;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: -1.35rem;
    margin-left: -1rem;
    border: 6px solid #224552;
    border-radius: 100px;
    cursor: pointer;
    display: block;
    outline: none;
    position: absolute;
}

.surplus .input-range__slider {
    border-color: var(--primary);
}

.overstock .input-range__slider {
    border-color: var(--overstock);
}

.input-range__slider:active {
    transform: scale(1.1);
}

.input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}

.surplus .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(232, 61, 86, 0.2);
}

.overstock .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(82, 179, 161, 0.2);
}

.input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
}

.input-range__slider-container {
    transition: left 0.1s ease-out;
}

.input-range__label {
    transition: all 0.1s ease-in-out;
    transform: translateZ(0);
    white-space: nowrap;
    bottom: -2.5rem;
    position: absolute;
}

.input-range__label--value {
    font-size: 16px;
}

.input-range__label--min {
    left: 1rem;
}

.input-range__label--max {
    right: 1rem;
}

.input-range__label-container {
    left: -50%;
    position: relative;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    color: white;
    transition: all 0.1 ease-in-out;
}

.surplus .input-range__label-container {
    background-color: var(--primary);
}

.overstock .input-range__label-container {
    background-color: var(--overstock);
}

.input-range__label--min .input-range__label-container {
    background-color: transparent;
    color: var(--text_light);
}

.input-range__label--max .input-range__label-container {
    left: 50%;
    background-color: transparent;
    color: var(--text_light);
}

.input-range__track {
    background: #eeeeee;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    display: block;
    height: 0.75rem;
    position: relative;
}

.input-range--disabled .input-range__track {
    background: #eeeeee;
}

.input-range__track--background {
    position: relative;
    margin-top: -0.15rem;
    height: 0.75rem;
    width: 90%;
    margin: auto;
    left: 0;
    right: 0;
    top: 50%;
    overflow: visible;
    z-index: 1;
}

.input-range__track--active {
    padding-left: 0.25rem;
    border-radius: 20px;
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
}

.surplus .input-range__track--active {
    background: var(--primary);
}

.overstock .input-range__track--active {
    background: var(--overstock);
}

.input-range {
    position: relative;
    width: 100%;
}

body.dark {
    --primary: #eb3d56;
    --overstock: #52b3a1;
    --accent: #0c191e;
    --gunmetal: #75b0c5;
    --text_light: #c4c4c4;
}

body.dark .input-range__slider {
    appearance: none;
    background: var(--accent);
    width: 1.75rem;
    height: 1.75rem;
    margin-top: -1.25rem;
    margin-left: -1rem;
    border: 5px solid var(--gunmetal);
    border-radius: 100px;
    cursor: pointer;
    display: block;
    outline: none;
    position: absolute;
}

body.dark .input-range__slider:active {
    transform: scale(1.3);
}

body.dark .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}

body.dark .input-range--disabled .input-range__slider {
    background: var(--text_light);
    border: 1px solid var(--text_light);
    box-shadow: none;
    transform: none;
}

body.dark .input-range__slider-container {
    transition: left 0.1s ease-out;
}

body.dark .input-range__label {
    color: var(--gunmetal);
    transition: all 0.1s ease-in-out;
    transform: translateZ(0);
    white-space: nowrap;
}

body.dark .input-range__label--min,
body.dark .input-range__label--max {
    bottom: -2.25rem;
    position: absolute;
    color: var(--text_light);
}

body.dark .input-range__label--min {
    left: 1rem;
}

body.dark .input-range__label--max {
    right: 1rem;
}

body.dark .input-range__label-container {
    left: -50%;
    position: relative;
}

body.dark .input-range__label--max .input-range__label-container {
    left: 50%;
}

body.dark .input-range__track {
    background: var(--accent);
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    display: block;
    height: 0.75rem;
    position: relative;
}

body.dark .input-range--disabled .input-range__track {
    background: var(--accent);
}

body.dark .input-range__track--background {
    position: relative;
    margin-top: -0.15rem;
    height: 0.75rem;
    width: 90%;
    margin: auto;
    left: 0;
    right: 0;
    top: 50%;
    overflow: visible;
}

body.dark .input-range__track--active {
    padding-left: 0.25rem;
    border-radius: 20px;
    background: var(--gunmetal);
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
}

/*# sourceMappingURL=index.css.map */
