@font-face {
    font-family: "Poppins";
    font-display: swap;
    src: local("Poppins"), local("Poppins"),
        url(https://fonts.gstatic.com/s/poppins/v13/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

.visible {
    opacity: 1;
}

.hidden {
    opacity: 0;
    display: none;
}

/* PLACEHOLDER Loading Animation (CSS Only, 1 DIV) */

:root {
    --color-bg: #f6fbfa;
    --color-bar: #ccc;
    --gradient: linear-gradient(72deg, var(--color-bar), var(--color-bg));
}

.placeholder {
    position: relative;
    width: 25em;
    max-width: 100%;
    margin-top: 2rem;
    padding-bottom: 14em;
    /* 14 rows */
    font-size: 10px;
    /* size of placeholder */
    background-image: var(--gradient);
    background-size: 400% 400%;
    animation: loading 1.5s ease-in-out infinite;

    /* hide inner text */
    text-indent: -9999px;
    white-space: nowrap;
    overflow: hidden;
}

.placeholder:before,
.placeholder:after {
    content: " ";
    display: block;
    position: absolute;
}

/* placeholder avatar */
.placeholder:before {
    z-index: 1;
    top: -1.5em;
    left: -1.5em;
    background-image: var(--gradient);
    background-size: 400% 400%;
    width: 4em;
    height: 4em;
    border: 1.5em solid var(--color-bg);
    animation: loading 1.5s ease-in-out infinite;
}

/* placeholder rows */
.placeholder:after {
    width: 100%;
    height: 1em;
    top: -1em;
    box-shadow:
    /* length, row (in em)*/ 22em 1em var(--color-bg), 0 2em var(--color-bg), 12em 3em var(--color-bg),
        0 4em var(--color-bg), 0 5em var(--color-bg), 0 6em var(--color-bg), 25em 7em var(--color-bg),
        0 8em var(--color-bg), 21em 9em var(--color-bg), 0 10em var(--color-bg), 24em 11em var(--color-bg),
        0 12em var(--color-bg), 18em 13em var(--color-bg), 0 14em var(--color-bg);
}

@keyframes loading {
    0% {
        background-position: 70% 0%;
    }

    50% {
        background-position: 30% 100%;
    }

    100% {
        background-position: 70% 0%;
    }
}

.modal-frontlayer {
    transition: all 0.1s ease-in-out !important;
    backdrop-filter: blur(5px);
}
