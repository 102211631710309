.Toastify__toast-container--bottom-center {
    margin-bottom: 4rem;
}

.Toastify__toast-body {
    flex-basis: 0;
}

.Toastify__toast--success {
    background-color: #224552;
    border-radius: 20px;
    padding: 1rem 1.5rem;
    margin: 0.75rem;
    font-family: "Poppins";
}

.Toastify__toast--success::after {
    content: " ";
    width: 1rem;
    height: 1rem;
    margin: auto;
    border-radius: 20px;
    background-color: #36ab80;
}

.Toastify__toast--warning {
    background-color: #224552;
    border-radius: 20px;
    padding: 0 1rem;
    margin: 0.75rem;
    font-family: "Poppins";
}

.Toastify__toast--warning::after {
    content: " ";
    width: 1rem;
    height: 1rem;
    margin: auto;
    border-radius: 20px;
    background-color: #f7af22;
}

.Toastify__toast--error {
    background-color: #224552;
    border-radius: 20px;
    padding: 1rem 1.5rem;
    margin: 0.75rem;
    font-family: "Poppins";
}

.Toastify__toast--error::after {
    content: " ";
    width: 1rem;
    height: 1rem;
    margin: auto;
    border-radius: 20px;
    background-color: #f4541d;
}

.Toastify__toast--info {
    background-color: #224552;
    border-radius: 20px;
    padding: 1rem 1.5rem;
    margin: 0.75rem;
    font-family: "Poppins";
}

.Toastify__toast--info::after {
    content: " ";
    width: 1rem;
    height: 1rem;
    margin: auto;
    border-radius: 20px;
    background-color: #0d8bf2;
}

.Toastify__toast {
    background-color: #224552;
    border-radius: 20px;
    color: white;
    padding: 1rem 1.5rem;
    margin: 0.75rem;
    font-family: "Poppins";
}

.Toastify__close-button {
    display: none;
}

.Toastify__progress-bar {
    background: white;
    border-radius: inherit;
}

.app_route,
.onboarding {
    margin-top: constant(safe-area-inset-top);
    /* iOS 11.0 */
    margin-top: env(safe-area-inset-top);
    /* iOS 11.2 */
    margin-bottom: constant(safe-area-inset-bottom);
    /* iOS 11.0 */
    margin-bottom: env(safe-area-inset-bottom);
    /* iOS 11.2 */
}
